import { graphql, Link } from 'gatsby';
import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import DefaultLayout from '../layouts/default-layout';
import RoundedButton from '../components/buttons/roundedButton';
import ArticleThemeContext from '../context/articleThemeContext';
import SocialShare from '../components/global/socialShare';
import basicMapDataToComponents from './basicMapDataToComponents';
import { makeAnchor } from '../components/article/faqList';
import { navigate } from 'gatsby-link';
import * as style from './fellow.module.scss';
import LogoSvg from '../components/global/logoSvg';

class Fellow extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0,
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('scroll', this.handleScrollListener);
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScrollListener);
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	handleScrollListener(event) {
		/*
		let advisorEl = document.querySelector('#BoardofAdvisors');
		let elDistanceToTop = window.pageYOffset + advisorEl.getBoundingClientRect().top;
		let toTopWrap = document.getElementById("to-top-wrap");
		if (document.scrollingElement.scrollTop > elDistanceToTop) {
			toTopWrap.classList.add('opacity-1');
		} else {
			toTopWrap.classList.remove('opacity-1');
		}
		*/
	}

	handleScrollToTop() {
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}

	startPage = () => {
		this.setState({ startPage: true });
	};

	render() {
		const {
			name,
			email,
			description,
			position,
			staff_type,
			twitter_link,
			linkedin_link,
			bluesky_link,
			long_bio,
			portrait_bio_image,
			portrait_bio_image_credit,
			bio_image,
			fellowship_stories,
			bottom_callouts
		} = this.props.data.allItemsJson.edges[0].node;

		const {
			projects_callout_heading,
			projects_callout_image,
			projects_button_text,
			projects_page_link
		} = this.props.data.ourTeam.edges[0].node;
		
		var bottomCallouts = bottom_callouts.filter(p => p.type === 'bottom_callouts');

		let link_back = '/projects';
		let link_back_name = 'Projects';
		if (this.props.location.state) {
			if (this.props.location.state.link_back) {
				link_back = this.props.location.state.link_back;
			} else {
				link_back = '/projects';
			}
			if (this.props.location.state.link_back_name) {
				link_back_name = this.props.location.state.link_back_name;
			} else {
				link_back_name = 'Projects';
			}
		}
		
		return (
			<DefaultLayout {...this.props} excludeParentFunctions>
				<div>
					<HeadTags
						title={name}
						location={this.props.location}
						image={
							bio_image ? bio_image : null
						}
						imageTwitter={
							bio_image ? bio_image : null
						}
					/>
					{/*
					<div className={style.logo}>
						<Link to="/">
							<LogoSvg />
						</Link>
					</div>
					*/}
					<div id="spacer" className={style.spacer}>&nbsp;</div>

					<div className={style.container}>
						<div className={style.staffWrap}>
							<div className={style.textCol}>
								<div
									onClick={() => {
										// navigate('/projects')
										navigate(link_back)
									}}
									className={style.toOurTeamLink}
								>
									<a
										className={`icon-circle-orange fa-arrow-left ${style.toOurTeamIcon}`}
										rel="noopener"
									>
										<span>TOP</span>
									</a>
									<a className={style.toOurTeamText}>
										{ link_back_name }
									</a>
								</div>

								<h1 className={style.staffName}>
									{name}
								</h1>

								<h3 className={style.staffPosition}>
									{position}
								</h3>

								<div
									className={style.staffBio}
									dangerouslySetInnerHTML={{ __html: long_bio }}
								>
								</div>

								<div className={style.socialWrap}>
									{email &&
										<a
											href={`mailto:${email}`}
											className={`icon-fa-all white-orange-hover fas fa-envelope`}
											target="_blank"
											rel="noopener"
										>
											<span>Email</span>
										</a>
									}
									{twitter_link &&
										<a
											href={twitter_link}
											className={`icon-social white-orange-hover ${style.socialItem}`}
											target="_blank"
											rel="noopener"
										>
											<span>Twitter</span>
											<div className={`icon-twitter x-white`}></div>
										</a>
									}
									{linkedin_link && linkedin_link.uri &&
										<a
											href={linkedin_link.uri}
											className={`icon-social white-orange-hover fas fa-linkedin-in ${style.socialItem}`}
											target="_blank"
											rel="noopener"
										>
											<span>LinkedIn</span>
										</a>
									}
									{bluesky_link && bluesky_link.uri &&
										<a
											href={bluesky_link.uri}
											className={`icon-social white-orange-hover fas fa-bluesky ${style.socialItem}`}
											target="_blank"
											rel="noopener"
										>
											<span>LinkedIn</span>
										</a>
									}

									<div className={style.socialShareMobile}>
										<ArticleThemeContext.Provider value="light">
											<SocialShare horizontal static color="orange" title={name} />
										</ArticleThemeContext.Provider>
									</div>
								</div>

							</div>

							<div className={style.imageCol}>
								<div className={style.bioImageWrap}>
									<img
										className={style.bioImage} src={portrait_bio_image}
										alt={name}
										title={name}
									/>
								</div>
								{portrait_bio_image_credit &&
									<div className={style.bioImageCredit}>
										{portrait_bio_image_credit}
									</div>
								}
							</div>
						</div>
					</div>
					
					{fellowship_stories && fellowship_stories.length > 0 &&
						<div
							id={'FellowshipStories'}
							className={style.fellowshipStories}
						>
							<h2 className={`${style.sectionHeading}`}>
								FELLOWSHIP STORIES
							</h2>
							<div
								className={`${style.sectionInset}`}
							>
								{fellowship_stories && fellowship_stories.length > 0 && fellowship_stories.map(function(item, i) {
									var authors = (item.authors) ? item.authors.map((author) => author.name).join(' / ') : null;
	
									return (
										<div
											className={`${style.sectionItem}`}
										>
											<a href={item.path}>
												<div
													className={`${style.storyImg}`}
													style={{ backgroundImage: `url(${item.featured_image.sizes.hero_sm})` }}
												></div>
											</a>
											<h3
												className={`${style.storyTitle}`}
											>
												<a href={item.path}>
													{item.title}&nbsp;<span className={style.angle}>&#8250;</span>
												</a>
											</h3>
											
											{/*
											{item.partner &&
												<div
													className={`${style.partner}`}
												>
													In Partnership with <span>{item.partner}</span>
												</div>
											}
											{authors &&
												<div
													className={`${style.authors}`}
												>
													By <span className={`${style.authorName}`}>{authors}</span>
												</div>
											}
											*/}
										</div>
									);
								})}
								
							</div>
						</div>
					}
					
					{bottomCallouts && bottomCallouts.length > 0 &&
						basicMapDataToComponents(bottomCallouts, { reveal: true })
					}
					
					{/*
					<div
						className={style.projectsCallout}
					>
						<div
							className={style.projectsCalloutImage}
							style={{ backgroundImage: 'url('+projects_callout_image+')'}}
						></div>

						<p className={style.projectsCalloutHeading}>
							{projects_callout_heading}
						</p>

						<span className={style.textLine} />

						<div
							className={style.projectsButtonWrap}
						>
							<RoundedButton
								centered={true}
								color={'white'}
								text={projects_button_text}
								onClick={() => {
										navigate(projects_page_link)
								}} type="button"
								url={projects_page_link}
							/>
						</div>

					</div>

					<ArticleThemeContext.Provider value="light">
						<SocialShare
							vertical
							color="orange"
							labelStyle={
								{}
							}
							title={name}
						/>
					</ArticleThemeContext.Provider>
					*/}
					
				</div>
			</DefaultLayout>
		);
	}
}

/*
bottom_callouts {
  type
  items {
	  title
	  content_type
	  path
	  cta_link {
		  url
		  title
		}
		callout_subtitle
		image {
			sizes {
				original
				wide_lg
				square_md
			}
			alt_text
		}
  }
}

authors {
  id
  name
}
partner
*/

export default Fellow;

// eslint-disable-next-line

export const corecurriculumPageQuery = graphql`
query FellowPage(
	$menuId: String!
	$isEs: Boolean!
	$idRegex: String!
	$blog: String!
	$translations: String!
	$authorId: String) {
		allItemsJson(filter: {content_type: {eq: "author"}, jsonId: {eq: $authorId}}) {
	  edges {
		node {
			name
			email
			description
			position
			staff_type
			twitter_link
			linkedin_link { 
				uri
				title
			}
			bluesky_link { 
				uri
				title
			}
			long_bio
			portrait_bio_image
			portrait_bio_image_credit
			bio_image
			fellowship_stories {
			  id
			  title
			  path
			  featured_image {
				  sizes {
						hero_lg
						hero_md
						hero_sm
						original
				  }
			  }
			}
		  bottom_callouts {
			  type
			  items {
				  title
				  content_type
				  path
				  cta_link {
					  url
					  title
					}
					image {
						sizes {
							original
							wide_lg
							square_md
						}
						alt_text
					}
			  }
		  }
		}
	  }
	}
	ourTeam: allItemsJson(filter: {content_type: {eq: "our_team"}}) {
    edges {
      node {
        projects_callout_heading
				projects_callout_image
				projects_button_text
				projects_page_link
      }
    }
  }
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
	...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
	...headerFieldsEs @include(if:$isEs)
	...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;